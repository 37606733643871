import React, { useState, useEffect } from "react";
import {
  Alert,
  Button,
  Carousel,
  Col,
  Drawer,
  Modal,
  Popconfirm,
  Row,
  Skeleton,
  Space,
} from "antd";
import { getDefaultCurrencyCode } from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import defaultPic from "../../assets/nophoto.png";
import { AiFillCheckCircle } from "react-icons/ai";
import {
  acceptSubRequestAction,
  getAllSubRequestedAction,
} from "../../store/subscription/subscriptionRequest/actions";
import RejectSubscriptionReqForm from "../forms/RejectSubscriptionReqForm";
import {
  getOneCustomerAction,
  updateCustomerAction,
} from "../../store/customer/actions";
import CancelSubRequest from "../forms/CancelSubscriptionRequestform";
import { BsCheckSquare } from "react-icons/bs";
import { BsXSquare } from "react-icons/bs";
import { Image } from "antd";
import { myLayoutActions } from "../../store/layout";
import { useLocation } from "react-router-dom";
import { IoCheckmark, IoCloseOutline } from "react-icons/io5";

const SubscriptionDrawer: React.FC<{
  open: boolean;
  onClose: any;
  data: any;
}> = ({ open, onClose, data }) => {
  const { company, auth, subscriptionRequest, customer } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
  const [openModel, setOpenModel] = useState(false);
  const [requestId, setRequestId] = useState("");
  const [reasonModal, setReasonModal] = useState(false);
  const [selectedReason, setSelectedReason] = useState("");
  const [currentGroupName, setCurrentGroupName] = useState("");
  const [confirmations, setConfirmations] = useState(
    data?.data?.customer?.confirmation || data?.customer?.confirmation || []
  );
  const location = useLocation();
  const route = location.pathname;
  const path = route.includes("account");
  const showReasonModal = (groupName: string) => {
    setReasonModal(true);
    setCurrentGroupName(groupName);
  };
  const cancelReason = () => {
    setReasonModal(false);
    setCurrentGroupName("");
  };
  const handleReasonSubmit = (groupName: string, reason: string) => {
    setSelectedReason(reason);
    handleConfirmation(groupName, false, reason);
    setReasonModal(false);
  };

  const showModal = (value: any) => {
    setOpenModel(true);
    setRequestId(value);
  };

  const cancelModal = () => {
    setOpenModel(false);
    setRequestId("");
  };
  useEffect(() => {
    setConfirmations(
      data?.data?.customer?.confirmation || data?.customer?.confirmation
    );
  }, [data?.data?.customer?.confirmation, data?.customer?.confirmation]);

  const handleConfirmation = async (
    groupName: string,
    isAccepted: boolean,
    reason: string
  ) => {
    const updatedConfirmations = JSON.parse(JSON.stringify(confirmations))?.map(
      (item: { groupName: string }) => {
        if (item.groupName === groupName) {
          return { ...item, isAccepted, reason };
        }
        return item;
      }
    );

    if (
      !updatedConfirmations?.some(
        (item: { groupName: string }) => item.groupName === groupName
      )
    ) {
      updatedConfirmations?.push({ groupName, isAccepted, reason });
    }

    await updateCustomerAction(auth?.token, data?.data?.customer?._id, {
      confirmation: updatedConfirmations,
    })(dispatch);

    setConfirmations(updatedConfirmations);
    dispatch(myLayoutActions.setSubReqStatus("requested"));
    await getOneCustomerAction(
      auth?.token,
      data?.data?.customer?._id
    )(dispatch);
    getAllSubRequestedAction(
      auth?.token,
      "?status=requested&limit=10&page=0"
    )(dispatch);
  };

  const handleAccept = async () => {
    const isAccepted = await acceptSubRequestAction(
      auth?.token,
      data?.data?._id,
      {
        status: "accepted",
      },
      "?status=requested&limit=10&page=0"
    )(dispatch);
    if (isAccepted) {
      onClose();
    }
  };
  const isAnyRejected =
    confirmations?.length < 6 ||
    confirmations?.some(
      (confirmation: any) => confirmation.isAccepted === false
    );

  const FinancialDetails = [
    {
      label: "Main Source Of Income",
      value: `${data?.data?.customer?.subscriptionInfo?.mainIncomeSource ||
        data?.customer?.subscriptionInfo?.mainIncomeSource ||
        "N/A"
        }`,
    },
    {
      label: "Occupation",
      value: `${data?.data?.customer?.subscriptionInfo?.occupationOrBusinessType ||
        data?.customer?.subscriptionInfo?.occupationOrBusinessType ||
        "N/A"
        }`,
    },
    {
      label: "Company / Institution",
      value: `${data?.data?.customer?.company?.toUpperCase() ||
        data?.customer?.company?.toUpperCase() ||
        "N/A"
        }`,
    },
    {
      label: "Monthly Income",
      value: `${data?.data?.customer?.subscriptionInfo?.monthlyIncome ||
        data?.customer?.subscriptionInfo?.monthlyIncome ||
        "N/A"
        }`,
    },
    {
      label: "Income Frequency",
      value: `${data?.data?.customer?.subscriptionInfo?.incomeFrequency ||
        data?.customer?.subscriptionInfo?.incomeFrequency ||
        "N/A"
        }`,
    },
    {
      label: "Do you currently own a Smartphone",
      value: `${data?.data?.customer?.subscriptionInfo?.ownsSmartphone ||
        data?.customer?.subscriptionInfo?.ownsSmartphone ||
        "N/A"
        }`,
    },
  ];
  const requestDetalis = [
    {
      label: "Shop",
      value: `${data?.data?.shop?.name ?? "N/A"}`,
    },
    {
      label: "Team Lead",
      value: `${data?.data?.createdBy?.names || data?.createdBy?.names}`,
    },
    {
      label: "Freelancer",
      value: `${data?.data?.createdBy?.names || data?.createdBy?.names}`,
    },
    {
      label: "Device",
      value: `${data?.data?.selectedPlan?.pricelistItem?.product?.model ||
        data?.productPlan?.pricelistItem?.product?.model
        }`,
    },
  ];
  const adressDetails = [
    {
      label: "Country",
      value: `${data?.data?.customer?.subscriptionInfo?.address?.country ||
        data?.customer?.subscriptionInfo?.address?.country
        }`,
    },
    {
      label: "Province",
      value: `${data?.data?.customer?.subscriptionInfo?.address?.province ||
        data?.customer?.subscriptionInfo?.address?.province
        }`,
    },
    {
      label: "District",
      value: `${data?.data?.customer?.subscriptionInfo?.address?.district ||
        data?.customer?.subscriptionInfo?.address?.district
        }`,
    },
    {
      label: "Sector",
      value: `${data?.data?.customer?.subscriptionInfo?.address?.sector ||
        data?.customer?.subscriptionInfo?.address?.sector
        }`,
    },
    {
      label: "Cell",
      value: `${data?.data?.customer?.subscriptionInfo?.address?.cell ||
        data?.customer?.subscriptionInfo?.address?.cell
        }`,
    },
    {
      label: "Village",
      value: `${data?.data?.customer?.subscriptionInfo?.address?.village ||
        data?.customer?.subscriptionInfo?.address?.village
        }`,
    },
  ];
  const customerFailedValue =
    customer?.userValidation?.data?.failedCustomerData;

  return (
    <Drawer
      title={
        <span
          style={{
            fontWeight: 500,
            color: "#030229",
            fontSize: "18px",
            marginLeft: "13px",
          }}
        >
          Request Details
        </span>
      }
      width={800}
      placement="right"
      closable={false}
      onClose={onClose}
      open={open}
      style={{ color: "[#030229]" }}
    >
      <div className="text-[#030229] p-2 opacity-95">
        {customer?.isFetching ? (
          <Skeleton active paragraph={{ rows: 1 }} />
        ) : (
          <>
            {customer?.invalidUserData?.data?.status === 400 && (
              <Alert
                message="The Customer phone number is invalid."
                type="error"
              />
            )}
            {customerFailedValue?.length > 0 && (
              <Alert
                className="mt-5"
                message={
                  <h1 className="text-sm font-poppins">
                    The phone number given does not match some customer data.
                    (Verify the ones with line through.)
                  </h1>
                }
                type="error"
              />
            )}
          </>
        )}
        <div className="border-[1px] border-[#03022910] flex justify-between p-6 rounded-lg relative mt-5 pb-6 ">
          {(path || ["accepted", "rejected", "paid", "done"]?.includes(
            data?.data?.status
          )) &&
            (confirmations?.find(
              (item: { groupName: string }) =>
                item.groupName === "Customer Details"
            )?.isAccepted === true ? (
              <div className="flex gap-3 absolute right-4 top-4">
                <Space className="bg-[#3AC722] text-white px-2 py-1 rounded-md text-[13px] font-light">
                  <IoCheckmark size={16} />     verified
                </Space>
              </div>
            ) : confirmations?.find(
              (item: { groupName: string }) =>
                item.groupName === "Customer Details"
            )?.isAccepted === false ? (
              <div className="flex gap-3 absolute right-4 top-4">
                <Space className="bg-[#f50721] text-white px-2 py-1 rounded-md text-[13px] font-light">
                  <IoCloseOutline size={16} />  rejected
                </Space>
              </div>
            ) : (
              <div className="flex gap-3 absolute right-4 top-4">
                <Space className="bg-[#DC9E00] text-white px-2 py-1 rounded-md text-[13px] font-light">
                  Not verified
                </Space>
              </div>
            ))}

          {["requested"]?.includes(
            data?.data?.status
          ) && (
              <div className="flex gap-3 absolute right-4 top-4">
                <BsXSquare
                  size={24}
                  className={`text-red-500 ${confirmations?.find(
                    (item: { groupName: string }) =>
                      item.groupName === "Customer Details"
                  )?.isAccepted === false
                    ? "bg-red-300"
                    : ""
                    }`}
                  onClick={() => showReasonModal("Customer Details")}
                />
                <BsCheckSquare
                  size={24}
                  className={`text-green-400 ${confirmations?.find(
                    (item: { groupName: string }) =>
                      item.groupName === "Customer Details"
                  )?.isAccepted === true
                    ? "bg-[#3AC722]"
                    : ""
                    }`}
                  onClick={async () =>
                    handleConfirmation("Customer Details", true, "")
                  }
                />
              </div>
            )}
          <div className="w-[340px] h-[200px]">
            <h1 className="font-medium text-[16px]  mb-4 ">Customer Details</h1>
            <div className="flex gap-8">
              <img
                className="w-24 h-24 object-cover rounded-full"
                src={
                  data?.data?.customer?.subscriptionInfo
                    ?.photoCustomerHoldingID ||
                  data?.customer?.subscriptionInfo?.photoCustomerHoldingID
                }
                alt="ptofilr"
              />
              <div className="font-normal ">
                <h1
                  className={`text-[16px] mb-3 `}
                >
                  {data?.data?.customer?.name}
                </h1>
                <div className=" flex flex-col gap-[6px] opacity-70 text-[14px] mt-2">
                  <p>
                    {data?.data?.customer?.nationalId ||
                      data?.customer?.nationalId}
                  </p>
                  <p>{data?.data?.customer?.email || data?.customer?.email}</p>
                  <span className="text-[13px]">
                    {data?.data?.customer?.phone || data?.customer?.phone}
                    {data?.data?.customer?.otherPhone?.map(
                      (el: any, index: number) => (
                        <p key={index}>{el?.tel}</p>
                      )
                    )}
                  </span>{" "}
                  <p className="text-[12px] capitalize">
                    {data?.data?.customer?.gender || data?.customer?.gender}
                  </p>
                  <button className="bg-[#D5D9DD] w-[97px] h-[30px] hover:bg-[#D5D9DD70] text-[#030229] font-medium  capitalize rounded-[16px] text-[10px] mt-[6px]">
                    {data?.data?.status || data?.status}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="text-[14px] w-[250px] h-[200px]">
            <h1 className="font-medium text-[16px]  mb-4">Address</h1>
            <div className="flex flex-col gap-[6px]">
              {adressDetails?.map((el: any) => (
                <Row>
                  <Col style={{ opacity: 0.6, fontSize: "13px " }} span={4}>
                    {el?.label}
                  </Col>
                  <Col style={{ fontSize: "13px " }} span={12} offset={4}>
                    {el?.value}
                  </Col>
                </Row>
              ))}
            </div>
          </div>
        </div>
        <div className="flex justify-between mt-8">
          <div className="border-[1px] border-[#03022910] p-3 rounded-lg w-[390px] h-[260px] relative">
            {(path || ["accepted", "rejected", "paid", "done"]?.includes(
              data?.data?.status
            )) &&
              (confirmations?.find(
                (item: { groupName: string }) => item.groupName === "Documents"
              )?.isAccepted === true ? (
                <div className="flex gap-3 absolute right-4 top-4">
                  <Space className="bg-[#3AC722] text-white px-2 py-1 rounded-md text-[13px] font-light">
                    <IoCheckmark size={16} />     verified
                  </Space>
                </div>
              ) : confirmations?.find(
                (item: { groupName: string }) =>
                  item.groupName === "Documents"
              )?.isAccepted === false ? (
                <div className="flex gap-3 absolute right-4 top-4">
                  <Space className="bg-[#f50721] text-white px-2 py-1 rounded-md text-[13px] font-light">
                    <IoCloseOutline size={16} />  rejected
                  </Space>
                </div>
              ) : (
                <div className="flex gap-3 absolute right-4 top-4">
                  <Space className="bg-[#DC9E00] text-white px-2 py-1 rounded-md text-[13px] font-light">
                    Not verified
                  </Space>
                </div>
              ))}
            {["requested",]?.includes(
              data?.data?.status
            ) && (
                <div className="flex gap-3 absolute right-4 top-4">
                  <BsXSquare
                    size={24}
                    className={`text-red-500 ${confirmations?.find(
                      (item: { groupName: string }) =>
                        item.groupName === "Documents"
                    )?.isAccepted === false
                      ? "bg-red-300"
                      : ""
                      }`}
                    onClick={() => showReasonModal("Documents")}
                  />
                  <BsCheckSquare
                    size={24}
                    className={`text-green-400 ${confirmations?.find(
                      (item: { groupName: string }) =>
                        item.groupName === "Documents"
                    )?.isAccepted === true
                      ? "bg-[#3AC722]"
                      : ""
                      }`}
                    onClick={async () =>
                      handleConfirmation("Documents", true, "")
                    }
                  />
                </div>
              )}
            <h1 className="font-medium text-[16px]  mb-2">Documents</h1>
            <div className="flex justify-between">
              <div>
                <p className="mb-2 opacity-80">National ID</p>
                <Image
                  width={180}
                  height={180}
                  className="w-full h-full object-cover"
                  src={
                    data?.data?.customer?.subscriptionInfo?.nationalIDDoc ||
                    data?.customer?.subscriptionInfo?.nationalIDDoc
                  }
                />
              </div>
              <div>
                <p className="mb-2 opacity-80">Picture</p>
                <Image
                  width={180}
                  height={180}
                  className="w-full h-full object-cover"
                  src={
                    data?.data?.customer?.subscriptionInfo
                      ?.photoCustomerHoldingID ||
                    data?.customer?.subscriptionInfo?.photoCustomerHoldingID
                  }
                />
              </div>
            </div>
          </div>
          <div className="border-[1px] border-[#03022910] p-6 rounded-lg w-[310px] h-[260px] relative overflow-y-auto">
            {(path || ["accepted", "rejected", "paid", "done"]?.includes(
              data?.data?.status
            )) &&
              (confirmations?.find(
                (item: { groupName: string }) =>
                  item.groupName === "Witness Details"
              )?.isAccepted === true ? (
                <div className="flex gap-3 absolute right-4 top-4">
                  <Space className="bg-[#3AC722] text-white px-2 py-1 rounded-md text-[13px] font-light">
                    <IoCheckmark size={16} />     verified
                  </Space>
                </div>
              ) : confirmations?.find(
                (item: { groupName: string }) =>
                  item.groupName === "Witness Details"
              )?.isAccepted === false ? (
                <div className="flex gap-3 absolute right-4 top-4">
                  <Space className="bg-[#f50721] text-white px-2 py-1 rounded-md text-[13px] font-light">
                    <IoCloseOutline size={16} />  rejected
                  </Space>
                </div>
              ) : (
                <div className="flex gap-3 absolute right-4 top-4">
                  <Space className="bg-[#DC9E00] text-white px-2 py-1 rounded-md text-[13px] font-light">
                    Not verified
                  </Space>
                </div>
              ))}
            {["requested",]?.includes(
              data?.data?.status
            ) && (
                <div className="flex gap-3 absolute right-4 top-4">
                  <BsXSquare
                    size={24}
                    className={`text-red-500 ${confirmations?.find(
                      (item: { groupName: string }) =>
                        item.groupName === "Witness Details"
                    )?.isAccepted === false
                      ? "bg-red-300"
                      : ""
                      }`}
                    onClick={() => showReasonModal("Witness Details")}
                  />
                  <BsCheckSquare
                    size={24}
                    className={`text-green-400 ${confirmations?.find(
                      (item: { groupName: string }) =>
                        item.groupName === "Witness Details"
                    )?.isAccepted === true
                      ? "bg-[#3AC722]"
                      : ""
                      }`}
                    onClick={async () =>
                      handleConfirmation("Witness Details", true, "")
                    }
                  />
                </div>
              )}
            <h1 className="font-medium text-[16px]  mb-4">Witness Details</h1>
            {(data?.data?.customer?.subscriptionInfo?.witnessInfo ||
              data?.customer?.subscriptionInfo?.witnessInfo) && (
                <div className="divide-y-2">
                  {(data?.data?.customer?.subscriptionInfo?.witnessInfo || data?.customer?.subscriptionInfo?.witnessInfo)?.map((el: any) => {
                    return (
                      <div className="flex flex-col gap-1 text-[14px] overflow-y-auto py-2">
                        <Row>
                          <Col style={{ opacity: 0.6, fontSize: "13px " }} span={6}>
                           Name :{" "}
                          </Col>
                          <Col style={{ fontSize: "13px " }} span={8} offset={4}>
                            {el?.name ||
                              data?.customer?.subscriptionInfo?.witnessInfo?.at(0)
                                ?.name}
                          </Col>
                        </Row>
                        <Row>
                          <Col style={{ opacity: 0.6, fontSize: "13px " }} span={6}>
                            Phone :{" "}
                          </Col>
                          <Col style={{ fontSize: "13px " }} span={8} offset={4}>
                            {el.phone ||
                              data?.customer?.subscriptionInfo?.witnessInfo?.at(0)
                                ?.phone}
                          </Col>
                        </Row>
                        <Row>
                          <Col style={{ opacity: 0.6, fontSize: "13px " }} span={8}>
                            Relationship :{" "}
                          </Col>
                          <Col style={{ fontSize: "13px " }} span={8} offset={2}>
                            {el?.relationship ||
                              data?.customer?.subscriptionInfo?.witnessInfo?.at(0)
                                ?.relationship}
                          </Col>
                        </Row>
                      </div>
                    )
                  })}

                </div>
              )}
          </div>
        </div>
        <div className="border-[1px] border-[#03022910] p-4 rounded-lg mt-8 relative">
          <div className="absolute right-2 top-2">
            {(path || ["accepted", "rejected", "paid", "done"]?.includes(
              data?.data?.status
            )) &&
              (confirmations?.find(
                (item: { groupName: string }) =>
                  item.groupName === "Financial Details"
              )?.isAccepted === true ? (
                <div className="flex gap-3 absolute right-4 top-4">
                  <Space className="bg-[#3AC722] text-white px-2 py-1 rounded-md text-[13px] font-light">
                    <IoCheckmark size={16} />     verified
                  </Space>
                </div>
              ) : confirmations?.find(
                (item: { groupName: string }) =>
                  item.groupName === "Financial Details"
              )?.isAccepted === false ? (
                <div className="flex gap-3 absolute right-4 top-4">
                  <Space className="bg-[#f50721] text-white px-2 py-1 rounded-md text-[13px] font-light">
                    <IoCloseOutline size={16} />  rejected
                  </Space>
                </div>
              ) : (
                <div className="flex gap-3 absolute right-3 top-3 w-24">
                  <Space className="bg-[#DC9E00] text-white px-2 py-1 rounded-md text-[13px] font-light">
                    Not verified
                  </Space>
                </div>
              ))}
            {["requested",]?.includes(
              data?.data?.status
            ) && (
                <div className="flex gap-3 absolute right-4 top-4">
                  <BsXSquare
                    size={24}
                    className={`text-red-500 ${confirmations?.find(
                      (item: { groupName: string }) =>
                        item.groupName === "Financial Details"
                    )?.isAccepted === false
                      ? "bg-red-300"
                      : ""
                      }`}
                    onClick={() => showReasonModal("Financial Details")}
                  />
                  <BsCheckSquare
                    size={24}
                    className={`text-green-400 ${confirmations?.find(
                      (item: { groupName: string }) =>
                        item.groupName === "Financial Details"
                    )?.isAccepted === true
                      ? "bg-[#3AC722]"
                      : ""
                      }`}
                    onClick={async () =>
                      handleConfirmation("Financial Details", true, "")
                    }
                  />
                </div>
              )}
          </div>
          <h1 className="font-medium text-[16px] mb-4">Financial Details</h1>
          <div className="flex flex-col gap-3 text-[14px]">
            {FinancialDetails?.map((el: any) => (
              <Row>
                <Col style={{ opacity: 0.6, fontSize: "13px " }} span={12}>
                  {el?.label} :{" "}
                </Col>
                <Col style={{ fontSize: "13px " }} span={12}>
                  {el?.value}
                </Col>
              </Row>
            ))}
          </div>
        </div>
        <div className="w-full h-[7rem] border-[1px] border-[#03022910] py-4 px-6 rounded-lg my-8 overflow-y-auto text-[#030229]">
          <h1 className="font-medium text-base  mb-3">Comments</h1>
          <p className="text-sm font-light">
            {data?.data?.customer?.comment || data?.customer?.comment}
          </p>
        </div>
        <div className="border-[1px] border-[#03022910] p-6 rounded-lg mt-8 relative">
          {(path || ["accepted", "rejected", "paid", "done"]?.includes(
            data?.data?.status
          )) &&
            (confirmations?.find(
              (item: { groupName: string }) =>
                item.groupName === "Request Details"
            )?.isAccepted === true ? (
              <div className="flex gap-3 absolute right-4 top-4">
                <Space className="bg-[#3AC722] text-white px-2 py-1 rounded-md text-[13px] font-light">
                  <IoCheckmark size={16} />     verified
                </Space>
              </div>
            ) : confirmations?.find(
              (item: { groupName: string }) =>
                item.groupName === "Request Details"
            )?.isAccepted === false ? (
              <div className="flex gap-3 absolute right-4 top-4">
                <Space className="bg-[#f50721] text-white px-2 py-1 rounded-md text-[13px] font-light">
                  <IoCloseOutline size={16} />  rejected
                </Space>
              </div>
            ) : (
              <div className="flex gap-3 absolute right-4 top-4">
                <Space className="bg-[#DC9E00] text-white px-2 py-1 rounded-md text-[13px] font-light">
                  Not verified
                </Space>
              </div>
            ))}
          {["requested",]?.includes(
            data?.data?.status
          ) && (
              <div className="flex gap-3 absolute right-4 top-4">
                <BsXSquare
                  size={24}
                  className={`text-red-500 ${confirmations?.find(
                    (item: { groupName: string }) =>
                      item.groupName === "Request Details"
                  )?.isAccepted === false
                    ? "bg-red-300"
                    : ""
                    }`}
                  onClick={() => showReasonModal("Request Details")}
                />
                <BsCheckSquare
                  size={24}
                  className={`text-green-400 ${confirmations?.find(
                    (item: { groupName: string }) =>
                      item.groupName === "Request Details"
                  )?.isAccepted === true
                    ? "bg-[#3AC722]"
                    : ""
                    }`}
                  onClick={async () =>
                    handleConfirmation("Request Details", true, "")
                  }
                />
              </div>
            )}
          <h1 className="font-medium text-[16px]  mb-4">Request Details</h1>
          <div className="flex flex-col gap-2 text-[14px]">
            {requestDetalis?.map((el: any) => (
              <Row>
                <Col style={{ opacity: 0.6, fontSize: "13px " }} span={4}>
                  {el?.label} :
                </Col>
                <Col style={{ fontSize: "13px " }} span={12} offset={2}>
                  {el?.value}
                </Col>
              </Row>
            ))}
          </div>
        </div>
        <div className="border-[1px] border-[#03022910] p-6 rounded-lg mt-8 relative">
          {(path || ["accepted", "rejected", "paid", "done"]?.includes(
            data?.data?.status
          )) &&
            (confirmations?.find(
              (item: { groupName: string }) => item.groupName === "Plan Details"
            )?.isAccepted === true ? (
              <div className="flex gap-3 absolute right-4 top-4">
                <Space className="bg-[#3AC722] text-white px-2 py-1 rounded-md text-[13px] font-light">
                  <IoCheckmark size={16} />     verified
                </Space>
              </div>
            ) : confirmations?.find(
              (item: { groupName: string }) =>
                item.groupName === "Plan Details"
            )?.isAccepted === false ? (
              <div className="flex gap-3 absolute right-4 top-4">
                <Space className="bg-[#f50721] text-white px-2 py-1 rounded-md text-[13px] font-light">
                  <IoCloseOutline size={16} />  rejected
                </Space>
              </div>
            ) : (
              <div className="flex gap-3 absolute right-4 top-4">
                <Space className="bg-[#DC9E00] text-white px-2 py-1 rounded-md text-[13px] font-light">
                  Not verified
                </Space>
              </div>
            ))}
          {["requested",]?.includes(
            data?.data?.status
          ) && (
              <div className="flex gap-3 absolute right-4 top-4">
                <BsXSquare
                  size={24}
                  className={`text-red-500 ${confirmations?.find(
                    (item: { groupName: string }) =>
                      item.groupName === "Plan Details"
                  )?.isAccepted === false
                    ? "bg-red-300"
                    : ""
                    }`}
                  onClick={() => showReasonModal("Plan Details")}
                />
                <BsCheckSquare
                  size={24}
                  className={`text-green-400 ${confirmations?.find(
                    (item: { groupName: string }) =>
                      item.groupName === "Plan Details"
                  )?.isAccepted === true
                    ? "bg-[#3AC722]"
                    : ""
                    }`}
                  onClick={async () =>
                    handleConfirmation("Plan Details", true, "")
                  }
                />
              </div>
            )}
          <h1 className="font-medium text-[16px]  mb-4">Plan Details</h1>
          <div className="flex gap-6">
            <div className="w-[98px] h-[110px] bg-[#F4F6FA] rounded-[10px] p-3 border-[.1px] border-[#D9DBE9] border-opacity-20">
              <Carousel autoplay arrows>
                {data?.data?.selectedPlan?.pricelistItem?.product?.images
                  ?.length > 0 ? (
                  data?.data?.selectedPlan?.pricelistItem?.product?.images?.map(
                    (el: any) => (
                      <img
                        src={el}
                        alt={`images`}
                        className="w-24 h-24 object-cover"
                      />
                    )
                  )
                ) : (
                  <img
                    src={defaultPic}
                    alt={`images`}
                    className="w-24 h-24 object-cover"
                  />
                )}
              </Carousel>
            </div>
            <div className="flex flex-col">
              <h1 className="text-sm font-medium mb-2">
                {data?.data?.selectedPlan?.pricelistItem?.product?.model ||
                  data?.productPlan?.pricelistItem?.product?.model}
              </h1>
              <Space>
                <p className=" opacity-60">
                  Brand :{" "}
                  {data?.data?.selectedPlan?.pricelistItem?.product?.brand ||
                    data?.productPlan?.pricelistItem?.product?.brand}
                </p>
                <p></p>
              </Space>
              <Space>
                <p className=" opacity-60">
                  Capacity{" "}
                  {data?.data?.selectedPlan?.pricelistItem?.specification?.at(
                    3
                  ) || data?.productPlan?.pricelistItem?.specification?.at(3)}
                </p>
                <p></p>
              </Space>
              <Space>
                <p className=" opacity-60">
                  Memory{" "}
                  {data?.data?.selectedPlan?.pricelistItem?.specification?.at(
                    2
                  ) || data?.productPlan?.pricelistItem?.specification?.at(2)}
                </p>
                <p></p>
              </Space>
            </div>
          </div>
          <div className="flex justify-between mt-4">
            <div className="flex flex-col gap-2">
              <h3 className="font-medium mb-2">Allowance : </h3>
              <Space>
                <AiFillCheckCircle color="#605BFF90" />
                <p
                  className={`opacity-60 ${(data?.data?.selectedPlan?.bundle?.at(0) === undefined ||
                    data?.productPlan?.bundle?.at(0) === undefined) &&
                    "line-through"
                    }`}
                >
                  Bundles :{" "}
                  {data?.data?.selectedPlan?.bundle?.at(0) ||
                    data?.productPlan?.bundle?.at(0) ||
                    0}{" "}
                  / Per Month
                </p>
                <p></p>
              </Space>
              <Space>
                <AiFillCheckCircle color="#605BFF90" />
                <p
                  className={`opacity-60 ${(data?.data?.selectedPlan?.sms?.at(0) === undefined ||
                    data?.productPlan?.sms?.at(0) === undefined) &&
                    "line-through"
                    }`}
                >
                  SMS :{" "}
                  {data?.data?.selectedPlan?.sms?.at(0) ||
                    data?.productPlan?.sms?.at(0) ||
                    0}{" "}
                  / Per Month
                </p>
                <p></p>
              </Space>
              <Space>
                <AiFillCheckCircle color="#605BFF90" />
                <p
                  className={`opacity-60 ${(data?.data?.selectedPlan?.call?.at(0) === undefined ||
                    data?.productPlan?.call?.at(0) === undefined) &&
                    "line-through"
                    }`}
                >
                  Airtime :{" "}
                  {data?.data?.selectedPlan?.call?.at(0) ||
                    data?.productPlan?.call?.at(0) ||
                    0}{" "}
                  /Per Month
                </p>
                <p></p>
              </Space>
            </div>
            <div className="flex flex-col gap-2">
              <h3 className="font-medium mb-2">Instalment plan</h3>

              <Space>
                <AiFillCheckCircle color="#605BFF90" />
                <p className=" opacity-60">
                  Upfront :{" "}
                  {data?.data?.selectedPlan?.initialPayment?.toLocaleString() ||
                    data?.productPlan?.initialPayment?.toLocaleString()}
                  {getDefaultCurrencyCode(company)}
                </p>
                <p></p>
              </Space>
              <Space>
                <AiFillCheckCircle color="#605BFF90" />
                <p className=" opacity-60">
                  Monthly Payment :{" "}
                  {data?.data?.selectedPlan?.monthlyInstalment?.toLocaleString() ||
                    data?.productPlan?.monthlyInstalment?.toLocaleString()}{" "}
                  {getDefaultCurrencyCode(company)}
                </p>
                <p></p>
              </Space>
              <Space>
                <AiFillCheckCircle color="#605BFF90" />
                <p className=" opacity-60">
                  Duration :{" "}
                  {data?.data?.selectedPlan?.duration ||
                    data?.productPlan?.duration}{" "}
                  Months
                </p>
              </Space>
            </div>
          </div>
        </div>
        {!path && (
          <div className=" mt-4 float-right pb-6">
            {!["accepted", "rejected", "paid"]?.includes(
              data?.data?.status
            ) && (
                <div className="flex items-center space-x-1 cursor-pointer">
                  <Button
                    className="capitalize min-w-[50px] text-sm text-center outline-none font-normal"
                    danger
                    onClick={() => showModal(data?.data?.status)}
                  >
                    Reject
                  </Button>
                  <Popconfirm
                    title="Accept Request"
                    description="Are you sure to Accept this Subscription Request?"
                    onConfirm={handleAccept}
                    okText="Confirm"
                    cancelText="Cancel"
                    placement="top"
                    okButtonProps={{
                      loading:
                        subscriptionRequest?.isFetching ||
                        subscriptionRequest?.isGeneratingInvoice,
                      className: "bg-blue-500",
                    }}
                  >
                    <Button
                      className="capitalize min-w-[50px] bg-[#20BF55] font-normal text-sm text-white text-center border-none outline-none"
                      disabled={isAnyRejected}
                    >
                      Accept
                    </Button>
                  </Popconfirm>
                </div>
              )}
          </div>
        )}
      </div>
      <Modal
        open={openModel}
        onCancel={cancelModal}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
      >
        <RejectSubscriptionReqForm
          onCancel={cancelModal}
          requestId={data?.data?._id}
          accountId={data?.data?.account?._id}
          onCloseDrower={() => onClose()}
        />
      </Modal>
      <Modal
        title="Enter the Reason"
        open={reasonModal}
        onCancel={cancelReason}
        footer={null}
      >
        <CancelSubRequest
          onCancel={cancelReason}
          onReasonSubmit={(reason: string) =>
            handleReasonSubmit(currentGroupName, reason)
          }
          data={data?.data?.customer}
        />
      </Modal>
    </Drawer>
  );
};
export default SubscriptionDrawer;
