import React, { useEffect } from "react";
import { Divider, Spin } from "antd";
import { Table } from "antd";
import { Mycolumns } from "../../../assets/data/data";
import { useDispatch, useSelector } from "react-redux";
import { getWareHouseItemCartDataAction } from "../../../store/wareHouse/actions";
import { LoadingOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import { COMPANY_PROFILE_ID } from "../../../utils/constants";
import { getOneCompanyAction } from "../../../store/setting/company/actions";

const ProductInvoiceDetails = (props: any) => {
  const dispatch = useDispatch();
  const { auth, inventory, wareHouse, company } = useSelector(
    (state: any) => state
  );

  const billingdata = [
    {
      company: "Samphone",
      address: "Kigali City - Nyarugenge",
      email: "samphone@gmail.com",
      contact: "+25078888888",
    },
  ];

  const productId = inventory?.searchBySn?.data[0]?._id;
  useEffect(() => {
    auth?.token &&
      productId &&
      getWareHouseItemCartDataAction(
        auth?.token,
        `?warehouseProduct=${productId}`
      )(dispatch);
  }, [auth.token, dispatch]);

  const companyId = COMPANY_PROFILE_ID;

  useEffect(() => {
    auth?.token &&
      getOneCompanyAction(companyId as string, auth.token)(dispatch);
  }, [auth.token, dispatch, companyId]);

  const Mydata = wareHouse?.cartData?.data?.map((el: any) => {
    return {
      key: el?._id,
      model: el?.priceProduct?.product?.model,
      specs: el?.warehouseProduct?.product?.specs?.map((el: any) => {
        return (
          <div className="flex gap-5">
            <p>{el?.label}</p>
            {el?.value?.map((val: any) => val)}
          </div>
        );
      }),
      imei: el?.warehouseProduct?.serialNumber,
      amountPaid: el?.payment?.amount,
      mode: el?.successPayment?.at(0)?.mode,
      type: el?.priceProduct?.product?.type,
      customer: el?.cart?.customer?.name,
      date: el?.paidOn?.slice(0, 10),
      data: el,
    };
  });

  return (
    <div className="text-black mt-8 h-[22rem] overflow-y-auto pr-12">
      <div className="text-black min-h-screen px-4 bg-white mt-6 py-4 ">
        <div className="text-xl font-bold">
          <h2 className="text-center">INVOICE</h2>{" "}
        </div>
        <Divider />
        <>
          {wareHouse?.isFetching ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          ) : (
            wareHouse?.cartData?.data?.map((el: any) => (
              <div className=" mb-10 ">
                <div className="flex gap-10">
                  <p className="font-semibold text-[#000] text-[15px]">
                    Invoice Number
                  </p>
                  <p className="font-light  text-gray-500 text-[14px]">
                    {el?.cart?.cartId}
                  </p>
                </div>
                <div className="flex gap-10">
                  <p className="font-semibold text-[#000] text-[15px]">
                    Invoice Date
                  </p>
                  <p className="font-light text-[14px] text-gray-500">
                    {el?.paidOn?.slice(0, 10)}
                  </p>
                </div>
                <div className="flex gap-10">
                  <p className="font-semibold  text-[#000] text-[15px]">
                    Due Date
                  </p>
                  <p className="font-light text-[14px] text-gray-500"></p>
                </div>
              </div>
            ))
          )}

          <div className="flex text-black flex-row justify-between pt-2">
            <div>
              <h2 className="text-base font-semibold mb-2">
                Company Information
              </h2>

              {company?.isFetching ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : (
                <div>
                  <div className="flex gap-5">
                    <p className="text-sm font-semibold">Company</p>
                    <p className="text-sm">{company?.selected?.name}</p>
                  </div>

                  <div className="flex gap-5">
                    <p className="text-sm font-semibold">Email</p>
                    <p className="text-sm">{company?.selected?.email}</p>
                  </div>
                  <div className="flex gap-5 mt-1">
                    <p className="text-sm font-semibold">Contact</p>
                    <p className="text-sm">{company?.selected?.phone}</p>
                  </div>
                </div>
              )}
            </div>

            <div className="xw-[14.7rem] pr-52">
              <h2 className="text-base font-semibold mb-2">
                Customer Information
              </h2>
              {wareHouse?.isFetching ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : (
                wareHouse.cartData?.data?.map((el: any) => (
                  <div className="flex flex-col gap-1 cursor-pointer ">
                    <Link to={`/customers/${el?.cart?.customer?._id}`}>
                      <div className="flex gap-5">
                        <p className="text-sm font-semibold">Names</p>

                        <p className="text-sm">{el?.cart?.customer?.name}</p>
                        <p className="text-sm">
                          <FaArrowUpRightFromSquare color={"#03022980"} />
                        </p>
                      </div>
                      <div className="flex gap-5">
                        <p className="text-sm font-semibold">Address</p>
                        <p className="text-sm">
                          {
                            el?.cart?.customer?.subscriptionInfo?.address
                              ?.district
                          }
                        </p>
                      </div>
                      <div className="flex gap-5">
                        <p className="text-sm font-semibold">Email</p>
                        <p className="text-sm">{el?.cart?.customer?.email}</p>
                      </div>
                      <div className="flex gap-5">
                        <p className="text-sm font-semibold">Contact</p>
                        <p className="text-sm">{el?.cart?.customer?.phone}</p>
                      </div>
                    </Link>
                  </div>
                ))
              )}
            </div>
          </div>

          <Divider dashed />

          <div className="text-lg font-semibold mb-1">
            <h2>Products</h2>{" "}
          </div>

          <div className=" w-full  pt-2">
            <Table
              columns={Mycolumns}
              loading={wareHouse?.isFetching}
              dataSource={Mydata}
              pagination={false}
            />
          </div>
          {wareHouse?.cartData?.data?.map((el: any) => (
            <div className="flex flex-col mt-5 mb-6  float-right">
              <div className="flex justify-between">
                <p className="text-[.9rem] font-light">Subtotal</p>
                <h2 className="text-[.9rem] font-semibold ">
                  {el?.payment?.amount}
                </h2>
              </div>
              <div className="flex justify-between">
                <p className="text-[.9rem] font-light">Taxes</p>
                <h2 className="text-[.9rem] font-semibold ">0%</h2>
              </div>
              <div className="flex mt-2 justify-between gap-12">
                <p className="text-lg font-semibold">Total Amount Due</p>
                <h2 className="text-lg font-semibold float-right">
                  {el?.payment?.amount}
                </h2>
              </div>
            </div>
          ))}
        </>
      </div>
    </div>
  );
};

export default ProductInvoiceDetails;
