import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import defaultPic from "../../../assets/nophoto.png";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";
import CurstomeCardSkeleton from "../../../components/skeleton/CurstomeCardSkeleton";
import { getAllChannelAction } from "../../../store/channel/actions";
import { myAccounts } from "../../../store/account";
import { mySubscritionActions } from "../../../store/subscription/order";
import { searchValue } from "../../../utils/setColor";
import { Badge } from "antd";
import {
  getAccountStatisticsAction,
  getAllAccountAction,
} from "../../../store/account/actions";
import { Divider, Stack } from "@mui/material";
import { getDefaultCurrencyCode } from "../../../utils/helper";

const CustomerSubscription = (props: any) => {
  const navigate = useNavigate();
  const { auth, account, layout, customer, company } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
  const [selectedStatus, setSelectedStatus] = useState<any>("");
  const [selectedChannel, setSelectedChannel] = useState<any>("");
  const [selectedType, setSelectedType] = useState<any>("");
  const [limit, setLimit] = useState<any>(15);
  const [page, setPage] = useState<any>(1);
  const accountStatistics = account?.accountStatistics;
  const accountId = account?.all?.data?.at(0)?._id;

  useEffect(() => {
    if (auth?.token) {
      getAllAccountAction(
        auth?.token,
        `?customer=${customer?.selected?._id}&page=${page - 1}&limit=${limit}`
      )(dispatch);
      if (accountId) {
        getAccountStatisticsAction(
          auth?.token,
          `?accountId=${accountId}`
        )(dispatch);
      }
    }
  }, [
    auth?.token,
    dispatch,
    selectedType,
    selectedChannel,
    limit,
    page,
    selectedStatus,
    layout.searchResult,
    layout.searchFrom,
    customer?.selected?._id,
    accountId,
  ]);

  useEffect(() => {
    if (!accountId) {
      dispatch(myAccounts.setSelected(null));
      dispatch(mySubscritionActions.setSelected(null));
    }
  }, [accountId, dispatch]);

  useEffect(() => {
    auth?.token && getAllChannelAction(auth?.token)(dispatch);
  }, [auth?.token, dispatch]);

  return (
    <>
      {" "}
      <div className=" h-full w-full bg-white relative">
        <ScrollableFrame
          setLimit={setLimit}
          setPage={setPage}
          limit={limit}
          total={account?.all?.total}
          count={Math.ceil(account?.all?.total / limit)}
        >
          <div
            className={`w-full h-full flex flex-wrap gap-2 ${
              layout?.isSideNavOpen ? "gap-4" : "gap-8"
            } gap-y-8 text-[#030229]`}
          >
            {account?.isFetching ? (
              <div className="flex flex-wrap">
                {Array.from({ length: 4 })?.map((el: any) => (
                  <CurstomeCardSkeleton />
                ))}
              </div>
            ) : (
              <>
                {account?.all?.data?.map((items: any) => (
                  <Badge.Ribbon
                    key={items?._id}
                    text={
                      <span
                        style={{
                          fontSize: "12px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          fontWeight: 400,
                          paddingTop: "1.5px",
                        }}
                      >
                        {items?.status}
                      </span>
                    }
                    color={searchValue(items?.status)}
                    style={{ width: "5rem", padding: "3px 1px" }}
                  >
                    <div className="w-full xl:w-[386px] h-[182px] bg-white rounded-[10px] border-[.1px] shadow-[1px_4px_24px_0_rgba(192,192,192,0.1)] p-3 px-4 relative">
                      <div
                        onClick={(event: any) => {
                          const isDisabledDiv = event.target.closest(
                            ".ant-dropdown-trigger"
                          );
                          const isDisabledlabel =
                            event.target.closest(".ant-dropdown-menu");
                          if (!isDisabledDiv && !isDisabledlabel) {
                            navigate(`/subscription/account/${items?._id}`);
                          }
                        }}
                      >
                        <div className="flex gap-3 items-center">
                          <div>
                            {items?.productPlan ? (
                              <img
                                src={
                                  items?.productPlan?.pricelistItem?.channel
                                    ?.logo
                                }
                                className="w-16 h-14 rounded"
                                alt="Product Plan"
                              />
                            ) : (
                              <img
                                src={defaultPic}
                                className="w-16 h-14 rounded object-cover"
                                alt="Default"
                              />
                            )}
                          </div>
                          <div>
                            <p className="text-[#605BFF] text-base font-normal">
                              {items?.accountID}
                            </p>
                          </div>
                        </div>
                        <div className="flex  justify-between mt-3 text-[12px]">
                          <Stack
                            direction="column"
                            sx={{ alignItems: "center" }}
                          >
                            <p className="font-normal opacity-70">
                              Expected Amount{" "}
                            </p>
                            <p className="font-medium ">
                              {getDefaultCurrencyCode(company)}{" "}
                              {accountStatistics?.expectedAmount?.toLocaleString()}
                            </p>
                          </Stack>
                          <Divider orientation="vertical" flexItem />
                          <Stack
                            direction="column"
                            sx={{ alignItems: "center" }}
                          >
                            <p className="font-normal opacity-70">
                              Amount Paid{" "}
                            </p>
                            <p className="font-medium ">
                              {getDefaultCurrencyCode(company)}{" "}
                              {accountStatistics?.totalAmountPaid?.toLocaleString()}
                            </p>
                          </Stack>
                          <Divider orientation="vertical" flexItem />
                          <Stack
                            direction="column"
                            sx={{ alignItems: "center" }}
                          >
                            <p className="font-normal opacity-70">
                              {" "}
                              Remaining Amount:{" "}
                            </p>
                            <p className="font-medium">
                              {getDefaultCurrencyCode(company)}{" "}
                              {accountStatistics?.remainAmount?.toLocaleString()}
                            </p>
                          </Stack>
                        </div>

                        <p className="text-[11px] absolute bottom-3 left-4 flex flex-col gap-6">
                          {items?.startingDate?.slice(0, 10)} /{" "}
                          {items?.endingDate?.slice(0, 10)}
                        </p>
                        <p className="text-[11px] absolute bottom-3 right-4">
                          {items?.duration} months
                        </p>
                      </div>
                    </div>
                  </Badge.Ribbon>
                ))}
              </>
            )}
          </div>
        </ScrollableFrame>
      </div>
    </>
  );
};
export default CustomerSubscription;
