import React from "react";
import { useSelector } from "react-redux";
import { ReactComponent as EditSvg } from "../../assets/edit.svg";
import { searchValue } from "../../utils/setColor";

const CustomerCard = (props: any) => {
  const { layout } = useSelector((state: any) => state);
  const styles = {
    customShadow: {
      boxShadow: "0px 10px 20px 0px rgba(194, 194, 194, 0.16)",
    },
    border: {
      border: "0px 1px 0px 0px solid rgba(3, 2, 41, 0.04)",
    },
  };

  return (
    <div
      className={`bordedr borderd-[#0302290A] bg-white px-5 py-3  ${layout?.isSideNavOpen ? "lg:w-[560px]" : "lg:w-[580px]"
        } w-full rounded-md px-7`}
      style={styles.customShadow}
    >
      <div className="md:flex justify-between">
        <h1 className="text-[#030229] text-base font-medium">
          Customer Details
        </h1>
      </div>
      <div className="sm:flex gap-[26px] mb-2 mt-5">
        <div className="w-[130px] h-[100px] bg-white p-[5px] rounded-sm border border-gray-200">
          <img src={props?.data?.subscriptionInfo?.photoCustomerHoldingID ?? props?.data?.picture} alt="" className="w-full h-full object-cover rounded-sm" />
        </div>
        <div>
          <h1 className="text-[#030229] text-base font-medium">
            {props?.data?.name}
          </h1>
          <button className="text-white text-[10px] px-4 mt-2 py-1 rounded-md" style={{ background: `${searchValue(props?.data?.status)}` }}>
            {props?.data?.status}
          </button>
        </div>
      </div>
      <div className="flex w-[90%] gap-10 mt-10">
        <div>
          <p className="text-[#03022980]  text-sm">Customer Id</p>
          <p className="text-[#03022980]  text-sm pt-[6px]">Email</p>
          <p className="text-[#03022980]  text-sm pt-[6px]">Phone Number</p>
          <p className="text-[#03022980]  text-sm pt-[6px]">Starting Date</p>
          <p className="text-[#03022980]  text-sm pt-[6px]">Customer Type</p>
        </div>
        <div>
          <p className="text-[#605BFF] text-sm">
            {props?.data?.customerId ?? "N/A"}
          </p>
          <p className="text-[#030229] text-sm pt-[6px]">{props?.data?.email ?? 'N/A'}</p>
          <p className="text-[#030229] text-sm pt-[6px]">{props?.data?.phone}</p>
          <p className="text-[#030229] text-sm pt-[6px]">
            {props?.data?.createdAt?.substring(0, 10)}
          </p>
          <p className="text-[#030229] text-sm pt-[6px]">
            {props?.data?.subscriptionInfo?.customerType}
          </p>
        </div>
      </div>
      <div className="float-right">
        <EditSvg
          color="#605BFF"
          onClick={props?.onEdit}
          className="cursor-pointer"
        />
      </div>
    </div>
  );
};
export default CustomerCard;
