import React from "react";
import { Input } from "antd";

const SearchInput = (props: any) => {
  return (
    <Input.Search
      placeholder={`Search ${props?.placeholder || ""}`}
      onSearch={props?.onSearch}
      onChange={props?.onChange}
      enterButton
      style={{ width: 450 }}
      size="large"
    />
  );
};

export default SearchInput;
